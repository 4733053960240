import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import {
  BackButtonContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  TextDivider,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import {
  BookingType,
  isBookingType,
} from "../../../redux/bookings/bookings.types";
import Button from "../../UI/button/button.component";
import Addresses from "../../bookings/addresses.component";
import { defineVehicleType } from "../../../util-functions";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import BookingStatus from "../../bookings/booking-status/booking-status.component";

const CompletedJobDetails = () => {
  const navigate = useNavigate();

  const { currentUser, userLoading, userError } = useSelector(
    (state: RootState) => state.user
  );

  //get state that was passed to this route
  const { state } = useLocation();

  if (!state || !currentUser || !currentUser.driverNumber) {
    return (
      <Card>
        <RedHeader>Wrong Parameters...</RedHeader>
        <BackButtonContainer>
          <Button onClick={() => navigate("/drivers/jobs-history")}>
            Try Again
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  const job = state.job as BookingType;

  if (!isBookingType(job)) {
    return (
      <Card>
        <RedHeader>Wrong Job Parameters...</RedHeader>
        <BackButtonContainer>
          <Button onClick={() => navigate("/drivers/jobs-history")}>
            Try again
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  return (
    <Card>
      {userLoading && <Spinner />}
      {userError && <RedHeader>{userError}</RedHeader>}
      <>
        <BackButtonContainer>
          <ClickableArea onClick={() => navigate(-1)}>
            <h3>Go Back</h3>
          </ClickableArea>
        </BackButtonContainer>
        <GreenHeader>
          Your job on {job?.date} at {job?.time}
        </GreenHeader>
        <BookingStatus booking={job} />
        {!job.publicBooking && job.reasonOfReject && (
          <>
            <TextDivider>
              <GreenP> REASON </GreenP>
            </TextDivider>
            <GreenP>
              <strong>{job.reasonOfReject}</strong>
            </GreenP>
          </>
        )}
        <TextDivider>
          <GreenP> ADDRESSES </GreenP>
        </TextDivider>
        <Addresses booking={job} />
        <TextDivider>
          <GreenP> DETAILS </GreenP>
        </TextDivider>
        <GreenP>Passenger Name</GreenP>
        <p>{job.passengerName}</p>
        <br />
        <GreenP>Passenger Phone Number</GreenP>
        <p>{job.passengerPhone}</p>
        <br />
        <GreenP>Vehicle Type:</GreenP>
        <p>{defineVehicleType(job.vehicleType)}</p>
        {job.meetAndGreet && (
          <>
            <br />
            <GreenP>Meet & Greet requested</GreenP>
            <p>Yes</p>
          </>
        )}
        {job.hasPet && (
          <>
            <br />
            <GreenP>Pet Friendly</GreenP>
            <p>Yes</p>
          </>
        )}
        {job.driverNote && (
          <>
            <br />
            <GreenP>Note to the Driver</GreenP>
            <p>{job.driverNote}</p>
          </>
        )}
        <TextDivider>
          <GreenP> PRICE </GreenP>
        </TextDivider>
        <GreenHeader> £{job.quotedPrice}*</GreenHeader>
        <GreenP>
          * Amount the Driver should be paid. Any parking and/or toll fees and/or
          waitng time charges should be added. Please see{" "}
          <Link to="/terms">Terms and Conditions</Link>
        </GreenP>
      </>
    </Card>
  );
};

export default CompletedJobDetails;
