import { ChangeEvent, FC, useState } from "react";
import {
  ButtonColumnContainer,
  GreenHeader,
  RedP,
  SpreadButtonContainer,
} from "../../../global.styles";
import FormInput from "../../UI/form-input/form-input.component";
import Button from "../../UI/button/button.component";
import { changeBookingPrice } from "../../../firebase/admin";

type ChangeBookingPriceProps = {
  bookingId: string;
  currentPrice: string;
  onPriceReviewed: () => void;
};

const ChangeBookingPrice: FC<ChangeBookingPriceProps> = ({
  bookingId,
  currentPrice,
  onPriceReviewed,
}) => {
  const [price, setPrice] = useState("");
  const [error, setError] = useState("");

  const priceChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setPrice(event.target.value);
  };

  const submitHandler = async () => {
    if (price === "") {
      setError("What's the new price?");
      return;
    }
    try {
      await changeBookingPrice(bookingId, price);
      onPriceReviewed();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("Error setting new price...");
      }
    }
  };

  return (
    <>
      <ButtonColumnContainer>
        <GreenHeader>Current Price: £{currentPrice}</GreenHeader>
        {error && <RedP>{error}</RedP>}
        <FormInput
          label="Enter New Price"
          value={price}
          onChange={priceChangeHandler}
          required
        />
      </ButtonColumnContainer>
      <SpreadButtonContainer>
        <Button buttonType="inverted" type="button" onClick={onPriceReviewed}>
          Cancel
        </Button>
        <Button type="submit" onClick={submitHandler}>
          Set New Price
        </Button>
      </SpreadButtonContainer>
    </>
  );
};

export default ChangeBookingPrice;
