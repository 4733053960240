import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { IoDocumentsOutline, IoListSharp } from "react-icons/io5";
import { FaUsersGear } from "react-icons/fa6";

import { firestore } from "../../firebase/config";
import Card from "../UI/card/card.component";
import InnerCard from "../UI/inner-card/inner-card.component";
import { RootState } from "../../redux/store";
import {
  AmberHeader,
  GreenP,
  RedHeader,
  TextDivider,
} from "../../global.styles";
import { colors } from "../../style-variables";

const AdminPanel = () => {
  const navigate = useNavigate();

  const [errorMessage, setErrorMessage] = useState("");
  const [activeDriversAmount, setActiveDriversAmount] = useState(0);

  //get list of drivers waitng for approval (max 50)
  const { userList } = useSelector((state: RootState) => state.userList);

  useEffect(() => {
    const unsubscribe = onSnapshot(
      doc(firestore, "utilData", "driversData"),
      { includeMetadataChanges: true },
      (driversDataDoc) => {
        if (driversDataDoc.exists()) {
          // Handle document existence
          if (!driversDataDoc.metadata.hasPendingWrites) {
            // Data is up-to-date
            const driversData = {
              ...driversDataDoc.data(),
            };
            if (
              driversData &&
              driversData.activeDrivers &&
              typeof driversData.activeDrivers === "number"
            ) {
              setActiveDriversAmount(driversData.activeDrivers);
            }
          } else if (
            driversDataDoc.metadata.hasPendingWrites &&
            !driversDataDoc.metadata.fromCache
          ) {
            // Data is being updated, show a loading indicator
            // setLoading(true); // Loading state
            // Clear existing data
            // ... (Display a loading indicator)
          } else if (
            driversDataDoc.metadata.hasPendingWrites &&
            driversDataDoc.metadata.fromCache
          ) {
            // Data is outdated, refresh from the server
            // setLoading(true); // Loading state
            // Trigger a refresh from the server
            getDoc(doc(firestore, "utilData", "driversData"))
              .then((docSnap) => {
                if (docSnap.exists()) {
                  const driversData = {
                    ...driversDataDoc.data(),
                  };
                  if (
                    driversData &&
                    driversData.activeDrivers &&
                    typeof driversData.activeDrivers === "number"
                  ) {
                    setActiveDriversAmount(driversData.activeDrivers);
                  }
                  // setLoading(false);
                } else {
                  // Handle document not found
                  setErrorMessage("Problem loading stats");
                  // setLoading(false);
                  // ... (Display an error message or redirect)
                }
              })
              .catch((error) => {
                if (error instanceof Error) {
                  setErrorMessage(error.message);
                  // setLoading(false);
                } else {
                  setErrorMessage("Error fetching stats");
                  // setLoading(false);
                }
              });
          }
        } else {
          // Handle document not found
          setErrorMessage("driversData doc not found");
          // setLoading(false);
        }
      },
      (error) => {
        if (error instanceof Error) {
          setErrorMessage(error.message);
          // setLoading(false);
        } else {
          setErrorMessage("Error fetching a booking");
          // setLoading(false);
        }
      }
    );
    return () => unsubscribe();
  }, []);

  return (
    <Card>
      <>
        <TextDivider>
          <GreenP> STATS </GreenP>
        </TextDivider>
        <GreenP>
          Amount of active Drivers: <strong>{activeDriversAmount}</strong>
        </GreenP>
      </>
      {errorMessage && (
        <>
          <TextDivider>
            <GreenP> ERROR </GreenP>
          </TextDivider>
          <RedHeader>{errorMessage}</RedHeader>
        </>
      )}
      <TextDivider>
        <GreenP> CONTROLS </GreenP>
      </TextDivider>
      {userList && userList.length > 0 && (
        <InnerCard
          onClick={() => {
            navigate("approve-paperwork");
          }}
        >
          <IoDocumentsOutline size={32} color={colors.primary} />
          <div>
            <AmberHeader>New Paperwork For Approval</AmberHeader>
          </div>
        </InnerCard>
      )}
      <InnerCard
        onClick={() => {
          navigate("manage-users");
        }}
      >
        <FaUsersGear size={32} />
        <div>
          <h3>Manage Users</h3>
        </div>
      </InnerCard>
      <InnerCard
        onClick={() => {
          navigate("manage-bookings");
        }}
      >
        <IoListSharp size={32} />
        <div>
          <h3>Manage Bookings</h3>
        </div>
      </InnerCard>
    </Card>
  );
};

export default AdminPanel;
