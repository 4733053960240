import { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
import { doc, getDoc, onSnapshot } from "firebase/firestore";

import { firestore } from "../../firebase/config";
import { BookingType } from "../../redux/bookings/bookings.types";
// import { RootState } from "../../redux/store";
// import {
//   errorBookingDetailsRealTime,
//   loadingBookingDetails,
//   updateBookingDetailsRealTime,
// } from "../../redux/booking-details/bookingDetails.slice";
import Card from "../UI/card/card.component";
import Spinner from "../UI/spinner/spinner.component";
import {
  BackButtonContainer,
  ClickableArea,
  GreenHeader,
  GreenP,
  RedHeader,
  SpreadButtonContainer,
  TextDivider,
} from "../../global.styles";
import BookingStatus from "./booking-status/booking-status.component";
import Addresses from "./addresses.component";
import { defineVehicleType } from "../../util-functions";
import Button from "../UI/button/button.component";
import DriverInfo from "./driver-info.component";
import { cancelBookingRequest } from "../../firebase/bookings";
import Modal from "../UI/modal/modal.component";

const BookingDetails = () => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();

  const { bookingId } = useParams();

  // //Can't find the reason of keeping this state global
  // const { bookingDetailsLoading, bookingDetailsError, bookingDetails } =
  //   useSelector((state: RootState) => state.bookingDetails);

  const [bookingDetailsLoading, setBookingDetailsLoading] = useState(true);
  const [bookingDetailsError, setBookingDetailsError] = useState("");
  const [bookingDetails, setBookingDetails] = useState<BookingType | null>(
    null
  );
  const [openCancelModal, setOpenCancelModal] = useState(false);
  const [bookingCancelled, setBookingCancelled] = useState(false);

  //set onSnapshot (real time updates connection) for this booking
  useEffect(() => {
    if (bookingId) {
      const unsubscribe = onSnapshot(
        doc(firestore, "bookings", bookingId),
        { includeMetadataChanges: true },
        (bookingDoc) => {
          if (bookingDoc.exists()) {
            // Handle document existence
            if (!bookingDoc.metadata.hasPendingWrites) {
              // Data is up-to-date
              const updatedBooking = {
                id: bookingDoc.id,
                ...bookingDoc.data(),
                createdAt: bookingDoc.data().createdAt.toMillis(),
              };
              setBookingDetails(updatedBooking as BookingType);
              setBookingDetailsLoading(false);
            } else if (
              bookingDoc.metadata.hasPendingWrites &&
              !bookingDoc.metadata.fromCache
            ) {
              // Data is being updated, show a loading indicator
              setBookingDetailsLoading(true); // Loading state
              // Clear existing data
              // ... (Display a loading indicator)
            } else if (
              bookingDoc.metadata.hasPendingWrites &&
              bookingDoc.metadata.fromCache
            ) {
              // Data is outdated, refresh from the server
              setBookingDetailsLoading(true); // Loading state
              // Trigger a refresh from the server
              getDoc(doc(firestore, "bookings", bookingId))
                .then((docSnap) => {
                  if (docSnap.exists()) {
                    const updatedBooking = {
                      id: docSnap.id,
                      ...docSnap.data(),
                      createdAt: docSnap.data()!.createdAt.toMillis(),
                    };
                    setBookingDetails(updatedBooking as BookingType);
                    setBookingDetailsLoading(false);
                  } else {
                    // Handle document not found
                    setBookingDetailsError("Booking not found");
                    setBookingDetailsLoading(false);
                    // ... (Display an error message or redirect)
                  }
                })
                .catch((error) => {
                  if (error instanceof Error) {
                    setBookingDetailsError(error.message);
                    setBookingDetailsLoading(false);
                  } else {
                    setBookingDetailsError("Error fetching booking");
                    setBookingDetailsLoading(false);
                  }
                });
            }
          } else {
            // Handle document not found
            setBookingDetailsError("Booking not found");
            setBookingDetailsLoading(false);
            // ... (Display an error message or redirect)
          }
        },
        (error) => {
          if (error instanceof Error) {
            setBookingDetailsError(error.message);
            setBookingDetailsLoading(false);
          } else {
            setBookingDetailsError("Error fetching a booking");
            setBookingDetailsLoading(false);
          }
        }
      );
      return () => unsubscribe();
    } else {
      navigate("/404");
    }
  }, [bookingId, navigate]);
  //opening modal and firing action if confirmed
  const cancelBookingHandler = () => {
    setOpenCancelModal(true);
  };
  const cancelBookingConfirmed = async () => {
    if (bookingId) {
      setBookingDetailsLoading(true);
      try {
        const data = await cancelBookingRequest(bookingId);
        if (data && data.message && data.message === "Booking cancelled!") {
          setBookingCancelled(true);
        }
      } catch (error) {
        if (error instanceof Error) {
          setBookingDetailsError(error.message);
        } else {
          setBookingDetailsError(
            "Problem cancelling this booking... Please try again. If problem still exists, please let us know"
          );
        }
      }
      setBookingDetailsLoading(false);
    } else {
      navigate("/bookings");
    }
  };

  if (bookingCancelled) {
    return (
      <Card>
        <GreenHeader>Booking cancelled.</GreenHeader>
        <BackButtonContainer>
          <Button
            buttonType="green"
            onClick={() => {
              navigate("/bookings");
            }}
          >
            OK
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  return (
    <Card>
      {openCancelModal && (
        <Modal
          title="Cancel Booking"
          body="Cancel this booking? Are you sure?"
          confirmButtonText="Yes, Cancel"
          setOpenModal={setOpenCancelModal}
          onModalConfirm={cancelBookingConfirmed}
        />
      )}

      {bookingDetailsLoading && <Spinner />}
      {bookingDetailsError && (
        <>
          <RedHeader>{bookingDetailsError}</RedHeader>
          <BackButtonContainer>
            <Button onClick={() => navigate("/bookings")}>Ok</Button>
          </BackButtonContainer>
        </>
      )}
      {!bookingDetailsError && !bookingDetailsLoading && !bookingDetails && (
        <Card>
          <RedHeader>Wrong Parameters...</RedHeader>
          <Button onClick={() => navigate("/bookings")}>Try Again</Button>
        </Card>
      )}
      {bookingDetails && !bookingDetailsLoading && !bookingDetailsError && (
        <>
          <BackButtonContainer>
            <ClickableArea onClick={() => navigate(-1)}>
              <h3>Go Back</h3>
            </ClickableArea>
          </BackButtonContainer>
          <GreenHeader>
            Booking on {bookingDetails?.date} at {bookingDetails?.time}
          </GreenHeader>
          <BookingStatus booking={bookingDetails} />
          {!bookingDetails.publicBooking && bookingDetails.reasonOfReject && (
            <>
              <TextDivider>
                <GreenP> REASON </GreenP>
              </TextDivider>
              <GreenP>
                <strong>{bookingDetails.reasonOfReject}</strong>
              </GreenP>
            </>
          )}
          <TextDivider>
            <GreenP> DRIVER INFO </GreenP>
          </TextDivider>
          {bookingDetails.driverNumber && !bookingDetails.publicBooking && (
            <GreenP>
              <strong>
                Private request to Driver {bookingDetails.driverNumber}
              </strong>
            </GreenP>
          )}
          {bookingDetails.accepted && bookingDetails.acceptedBy ? (
            <DriverInfo driver={bookingDetails.acceptedBy} />
          ) : (
            <GreenP>
              Driver Info is not available untill Driver accepts the booking...
            </GreenP>
          )}
          <TextDivider>
            <GreenP> ADDRESSES </GreenP>
          </TextDivider>
          <Addresses booking={bookingDetails} />
          <TextDivider>
            <GreenP> DETAILS </GreenP>
          </TextDivider>
          <GreenP>Passenger Name</GreenP>
          <p>{bookingDetails.passengerName}</p>
          <br />
          <GreenP>Passenger Phone Number</GreenP>
          <p>{bookingDetails.passengerPhone}</p>
          <br />
          <GreenP>Vehicle Type:</GreenP>
          <p>{defineVehicleType(bookingDetails.vehicleType)}</p>
          {bookingDetails.hasPet && (
            <>
              <br />
              <GreenP>Pet Friendly</GreenP>
              <p>Yes</p>
            </>
          )}
          {bookingDetails.meetAndGreet && (
            <>
              <br />
              <GreenP>Meet & Greet requested</GreenP>
              <p>Yes</p>
            </>
          )}
          {bookingDetails.driverNote && (
            <>
              <br />
              <GreenP>Note to the Driver</GreenP>
              <p>{bookingDetails.driverNote}</p>
            </>
          )}
          <TextDivider>
            <GreenP> PRICE </GreenP>
          </TextDivider>
          <GreenHeader> £{bookingDetails.quotedPrice}*</GreenHeader>
          <GreenP>
            * Amount to pay to the Driver. Any parking and/or toll fees and/or
            waitng time charges to be added. Please see{" "}
            <Link to="/terms">Terms and Conditions</Link>
          </GreenP>
          {!bookingDetails.completed && (
            <>
              <TextDivider>
                <GreenP> ACTIONS </GreenP>
              </TextDivider>
              <SpreadButtonContainer>
                {/* <Button buttonType="inverted" onClick={() => {}}>
                  Edit
                </Button> */}
                <Button buttonType="warning" onClick={cancelBookingHandler}>
                  Cancel Booking
                </Button>
              </SpreadButtonContainer>
            </>
          )}
        </>
      )}
    </Card>
  );
};

export default BookingDetails;
