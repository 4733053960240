import { ChangeEvent, FormEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import Card from "../../components/UI/card/card.component";
import {
  AmberHeader,
  AmberP,
  BackButtonContainer,
  ButtonColumnContainer,
  GreenHeader,
  GreenP,
  RedHeader,
} from "../../global.styles";
import Spinner from "../../components/UI/spinner/spinner.component";
import FormInput from "../../components/UI/form-input/form-input.component";
import { SpacerVertical } from "../../components/UI/spacers/spacers.component";
import { StyledTextArea } from "../../components/UI/textarea/textarea.component";
import Button from "../../components/UI/button/button.component";
import useAutosizeTextArea from "../../components/UI/textarea/useAutosizedTextArea";
import { sendContactFormToEmails } from "../../firebase/admin";

const Contact = () => {
  const navigate = useNavigate();

  //form states
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [captchaToken, setCaptchaToken] = useState<string | null>(null);
  const [submitError, setSubmitError] = useState("");

  const [messageSent, setMessageSent] = useState(false);
  const [messageLoading, setMessageLoading] = useState(false);
  const [messageError, setMessageError] = useState("");

  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef.current, message);

  const nameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };
  const emailChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };
  const phoneChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    const maxLength = 17;
    setPhone(
      event.target.value.toUpperCase().replaceAll(" ", "").slice(0, maxLength)
    );
  };
  const messageChangeHandler = (event: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(event.target.value);
  };

  const submitHandler = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (name.length > 0 && email.length > 0 && message.length > 0) {
      //validate email
      const re =
        /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/;
      if (!re.test(String(email).toLowerCase())) {
        setSubmitError("Please enter a valid email.");
        return;
      }
      if (!captchaToken) {
        setSubmitError("Please confirm below that you are human, NOT A ROBOT.");
        return;
      }     
      setMessageLoading(true);
      try {
        await sendContactFormToEmails({
          name,
          email,
          phone: phone.length > 0 ? phone : "No phone number provided",
          message,
          captchaToken,
        });
        setMessageSent(true);
      } catch (error) {
        if (error instanceof Error) {
          setMessageError(error.message);
        } else {
          setMessageError("Error sending message. Please try again later");
        }
      }
      setMessageLoading(false);
    } else {
      setSubmitError("Please fill all requred fields");
      return;
    }
  };

  if (typeof process.env.REACT_APP_RECAPTCHA_SITE_KEY !== "string") {
    return (
      <Card>
        <>
          <AmberHeader>Environmet variable error</AmberHeader>
          <AmberP>Please reload the app</AmberP>
          <BackButtonContainer>
            <Button buttonType="primary" onClick={() => navigate("/")}>
              OK
            </Button>
          </BackButtonContainer>
        </>
      </Card>
    );
  }

  return (
    <Card>
      {messageSent ? (
        <>
          <GreenHeader>Thanks! Your message was received.</GreenHeader>
          <GreenP>We'll get back to You as soon as we can.</GreenP>
          <BackButtonContainer>
            <Button buttonType="green" onClick={() => navigate("/")}>
              OK
            </Button>
          </BackButtonContainer>
        </>
      ) : (
        <form onSubmit={submitHandler}>
          {messageError ? (
            <RedHeader>{messageError}</RedHeader>
          ) : (
            <GreenHeader>Contact Us</GreenHeader>
          )}
          <GreenP>
            Please submit Your message and details below, and we'll get back to
            You as soon as we can.
          </GreenP>
          <FormInput
            label="Your Name"
            id="name"
            type="text"
            onChange={nameChangeHandler}
            value={name}
            required
          />
          <FormInput
            label="Email"
            id="email"
            type="text"
            autoCapitalize="none"
            onChange={emailChangeHandler}
            value={email}
            required
          />
          <FormInput
            label="Phone Number (optional)"
            id="phone"
            type="text"
            onChange={phoneChangeHandler}
            value={phone}
            inputMode="numeric"
          />
          <SpacerVertical $paddingRem={2.5}>
            <StyledTextArea
              id="review-text"
              onChange={messageChangeHandler}
              placeholder="Your Message..."
              ref={textAreaRef}
              rows={2}
              value={message}
            />
          </SpacerVertical>
          {submitError && <RedHeader>{submitError}</RedHeader>}
          <ButtonColumnContainer>
            <ReCAPTCHA
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
              onChange={(val) => {
                setCaptchaToken(val);
                console.log(val);
              }}
            />
          </ButtonColumnContainer>
          <ButtonColumnContainer>
            {messageLoading ? (
              <Spinner />
            ) : captchaToken ? (
              <Button type="submit">Submit</Button>
            ) : (
              <GreenP>
                Please tick the box above before submiting your message.
              </GreenP>
            )}
          </ButtonColumnContainer>
        </form>
      )}
    </Card>
  );
};

export default Contact;
