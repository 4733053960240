import { useCallback, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  collection,
  DocumentData,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";

import { firestore } from "../../../firebase/config";
import {
  AmberP,
  BackButtonContainer,
  GreenHeader,
  GreenP,
  PushToLeftColumn,
  PushToRightColumn,
  RedHeader,
} from "../../../global.styles";
import { RootState } from "../../../redux/store";
import { BookingType } from "../../../redux/bookings/bookings.types";
import Card from "../../UI/card/card.component";
import Spinner from "../../UI/spinner/spinner.component";
import Button from "../../UI/button/button.component";
import { SpacerVertical } from "../../UI/spacers/spacers.component";
import InnerCard from "../../UI/inner-card/inner-card.component";
import { defineVehicleType } from "../../../util-functions";
import { FaDog } from "react-icons/fa";
import { MdEmojiPeople } from "react-icons/md";

const AcceptedJobs = () => {
  const navigate = useNavigate();

  const isFirstMount = useRef(true); // Track initial mount to prevent errors on double mount

  const { userLoading, userError, currentUser } = useSelector(
    (state: RootState) => state.user
  );
  const { approvedDriver, approvedDriverLoading, approvedDriverError } =
    useSelector((state: RootState) => state.approvedDriver);

  const [loading, setLoading] = useState(true);
  const [jobsLoading, setJobsLoading] = useState(false);
  const [jobsError, setJobsError] = useState("");
  const [jobs, setJobs] = useState<BookingType[]>([]);
  const [lastVisible, setLastVisible] = useState<DocumentData | null>(null);
  const [paginationOn, setPaginationOn] = useState(false);

  const timeStampNow = new Date().getTime();

  useEffect(() => {
    if (!userLoading && !approvedDriverLoading) {
      setLoading(false);
    }
  }, [userLoading, approvedDriverLoading]);

  const fetchJobs = useCallback(
    async (startAfterDoc: DocumentData | null = null) => {
      if (!approvedDriver) return;

      setJobsLoading(true);
      setJobsError("");
      try {
        let jobsQuery = query(
          collection(firestore, "bookings"),
          where("driverNumber", "==", approvedDriver.driverNumber),
          where("accepted", "==", true),
          where("completed", "==", false),
          orderBy("pickupTimestamp", "asc"),
          limit(10)
        );
        if (startAfterDoc) {
          jobsQuery = query(jobsQuery, startAfter(startAfterDoc));
        }
        const jobsSnapshot = await getDocs(jobsQuery);
        const newJobs = jobsSnapshot.docs.map((docSnapshot) => {
          const data = docSnapshot.data();
          return {
            ...data,
            //transforming timestamps from non-serializable to milliseconds number
            createdAt: data.createdAt?.toMillis() || null,
            acceptedAt: data.acceptedAt?.toMillis() || null,
            pickedUpAt: data.pickedUpAt?.toMillis() || null,
            //convert pickupGeoPoint into serializable object for Redux store
            pickupGeoPoint: {
              latitude: data.pickupGeoPoint?.latitude,
              longitude: data.pickupGeoPoint?.longitude,
            },
            id: docSnapshot.id,
          } as BookingType;
        });
        setJobs((prevJobs) => [...prevJobs, ...newJobs]);
        setLastVisible(jobsSnapshot.docs[jobsSnapshot.docs.length - 1]);
        setPaginationOn(jobsSnapshot.docs.length === 10);
      } catch (error) {
        setJobsError(
          error instanceof Error ? error.message : "Error fetching jobs"
        );
      } finally {
        setJobsLoading(false);
      }
    },
    [approvedDriver]
  );

  useEffect(() => {
    if (isFirstMount.current && approvedDriver) {
      isFirstMount.current = false;
      fetchJobs();
    }
  }, [fetchJobs, approvedDriver]);

  const observerRef = useRef<IntersectionObserver | null>(null);
  const lastJobRef = useRef<HTMLDivElement | null>(null); // Reference to the last job element

  useEffect(() => {
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    const observer = new IntersectionObserver(
      (entries) => {
        if (paginationOn && entries[0].isIntersecting && lastVisible) {
          fetchJobs(lastVisible);
        }
      },
      { rootMargin: "200px" }
    );
    observerRef.current = observer; // Assign the observer to the ref
    if (lastJobRef.current) {
      observer.observe(lastJobRef.current);
    }
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect(); // Clean up observer on unmount
      }
    };
  }, [fetchJobs, lastVisible, paginationOn]);

  if (loading) return <Spinner />;

  if (!currentUser?.readyToDrive || !approvedDriver) {
    return (
      <Card>
        <GreenHeader>Public Jobs</GreenHeader>
        <AmberP>
          Public jobs information is available only for Drivers with the Active
          Status!
        </AmberP>
        <BackButtonContainer>
          <Button onClick={() => navigate("/drivers/application")}>
            Check Driver Profile
          </Button>
        </BackButtonContainer>
      </Card>
    );
  }

  return (
    <Card>
      <BackButtonContainer>
        <Link to="/drivers/driver-panel">
          <h3>&larr; Driver Menu</h3>
        </Link>
      </BackButtonContainer>
      {userError && <RedHeader>{userError}</RedHeader>}
      {approvedDriverError && <RedHeader>{approvedDriverError}</RedHeader>}
      <GreenHeader>Accepted Jobs</GreenHeader>
      {approvedDriver.pausedRequests && (
        <AmberP>
          Your new requests are PAUSED!
          <br />
          Below You can see jobs that you have already accepted.
        </AmberP>
      )}
      {jobsError ? (
        <RedHeader>{jobsError}</RedHeader>
      ) : jobs.length === 0 ? (
        <>
          <SpacerVertical $paddingRem={1}>
            <p>Currently You don't have any accepted jobs.</p>
          </SpacerVertical>
          <BackButtonContainer>
            <Button onClick={() => navigate("/drivers/driver-panel")}>
              OK
            </Button>
          </BackButtonContainer>
        </>
      ) : (
        jobs.map((job, index) => {
          //server stores dates in UTC, it doesn't care about winter/summer time
          //if we use server timestamp to interract with client timestamps convert it like this:
          const serverPickupTime = new Date(job.pickupTimestamp!);
          const clientPickupTimestamp =
            serverPickupTime.getTime() +
            serverPickupTime.getTimezoneOffset() * 60 * 1000;
          return (
            <div
              key={job.id}
              ref={index === jobs.length - 1 ? lastJobRef : null}
            >
              <InnerCard
                onClick={() =>
                  navigate(`/drivers/accepted-job-details/${job.id}`)
                }
              >
                <PushToLeftColumn>
                  {timeStampNow > Number(clientPickupTimestamp) &&
                    !job.arrivedToPickup && <AmberP>RUNNING LATE!</AmberP>}
                  <h3>
                    {job.date} {job.time}
                  </h3>
                  <p>{job.pickupPostCode}</p>
                  <p>{job.durationTime} trip</p>
                </PushToLeftColumn>
                <PushToRightColumn>
                  <h3>£{job.quotedPrice}</h3>
                  <p>{defineVehicleType(job.vehicleType)}</p>
                  {job.meetAndGreet && (
                    <GreenP>
                      <MdEmojiPeople size={24} /> Meet&Greet
                    </GreenP>
                  )}
                  {job.hasPet && (
                    <GreenP>
                      <FaDog /> Pet
                    </GreenP>
                  )}
                </PushToRightColumn>
              </InnerCard>
            </div>
          );
        })
      )}
      {jobsLoading && <Spinner />}
    </Card>
  );
};

export default AcceptedJobs;
