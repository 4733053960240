import { ChangeEvent, FC, useState } from "react";
import {
  ButtonColumnContainer,
  RedP,
  SpreadButtonContainer,
} from "../../../global.styles";
import FormInput from "../../UI/form-input/form-input.component";
import Button from "../../UI/button/button.component";
import { assignDriver } from "../../../firebase/admin";
import Spinner from "../../UI/spinner/spinner.component";

type AssignDriverProps = {
  bookingId: string;
  onDriverAssigned: () => void;
  onCancel: () => void;
};

const AssignDriver: FC<AssignDriverProps> = ({
  bookingId,
  onDriverAssigned,
  onCancel,
}) => {
  const [driverNumber, setDriverNumber] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const driverNumberChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setDriverNumber(event.target.value);
  };

  const submitHandler = async () => {
    if (driverNumber === "") {
      setError("What's the Driver number?");
      return;
    }
    setLoading(true);
    try {
      await assignDriver(bookingId, driverNumber);
      setLoading(false);
      onDriverAssigned();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
        setLoading(false);
        return;
      } else {
        setError("Error assigning job for the Driver");
        setLoading(false);
        return;
      }
    }
  };

  if (loading) return <Spinner />;

  return (
    <>
      <ButtonColumnContainer>
        {error && <RedP>{error}</RedP>}
        <FormInput
          label="Enter Driver Number"
          value={driverNumber}
          onChange={driverNumberChangeHandler}
          required
        />
      </ButtonColumnContainer>
      <SpreadButtonContainer>
        <Button buttonType="inverted" type="button" onClick={onCancel}>
          Go Back
        </Button>
        <Button type="submit" onClick={submitHandler}>
          Assign Driver
        </Button>
      </SpreadButtonContainer>
    </>
  );
};

export default AssignDriver;
