// import { User } from "firebase/auth";
// import { Timestamp } from "firebase/firestore";

import { isVehicleType, VehicleType } from "../vehicles/vehicles.types";

export type CurrentUserType = {
  id: string;
  email: string;
  createdAt: number;
  lastUpdate: number;
  isBoss?: boolean;
  mega?: boolean;
  name?: string;
  phoneNumber?: string;
  approvedDriver?: boolean;
  readyToDrive?: boolean;
  paidSubscription?: boolean;
  driverNumber?: string;
  waitingDriverApproval?: boolean;
  hasAccount?: boolean;
  reasonForBan?: string;
  driversData?: DriversDataDocType & {
    licensingAuthority: string;
    petFriendly: boolean;
    amountOfVehicles?: number;
    dvlaLicenceNumber?: string;
    badgeNumber?: string;
    acceptedJobs?: number;
    completedJobs?: number;
    DVLACheckCode?: {
      code: string;
      pending: boolean;
      approved: boolean;
    };
  };
};

export type DriversDataDocType = {
  PHBadge?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
  PHDriversLicence?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
  DVLALicence?: {
    expires: number;
    expiringExpires: number;
    expiresSoon: boolean;
    downloadUrl: string;
    pendingUrl: string;
    expiringUrl: string;
    reasonOfDecline: string;
    approved: boolean;
  };
};

export type ApprovedDriverType = {
  id: string;
  driverNumber: string;
  name: string;
  phoneNumber: string;
  licensingAuthority: string;
  badgeNumber: string;
  availableVehicleTypes: VehicleType[];
  selectedVehicleTypes: VehicleType[];
  petFriendly: boolean;
  pausedRequests: boolean;
  PHvehicle: {
    numberPlate: string;
    licencePlate: string;
    make: string;
    model: string;
    color: string;
  };
};
//type
export function isApprovedDriver(obj: any): obj is ApprovedDriverType {
  return (
    typeof obj.id === "string" &&
    typeof obj.driverNumber === "string" &&
    typeof obj.name === "string" &&
    typeof obj.phoneNumber === "string" &&
    typeof obj.licensingAuthority === "string" &&
    typeof obj.badgeNumber === "string" &&
    Array.isArray(obj.availableVehicleTypes) &&
    obj.availableVehicleTypes.every(isVehicleType) &&
    Array.isArray(obj.selectedVehicleTypes) &&
    obj.selectedVehicleTypes.every(isVehicleType) &&
    typeof obj.petFriendly === "boolean" &&
    (obj.pausedRequests === undefined ||
      typeof obj.pausedRequests === "boolean") &&
    typeof obj.PHvehicle.numberPlate === "string" &&
    typeof obj.PHvehicle.licencePlate === "string" &&
    typeof obj.PHvehicle.make === "string" &&
    typeof obj.PHvehicle.model === "string" &&
    typeof obj.PHvehicle.color === "string"
  );
}

export type UpdateNameType = {
  id: string;
  name: string;
};

export type UpdatePhoneType = {
  id: string;
  phoneNumber: string;
};

export type AddLicenseAuthorityType = {
  id: string;
  licensingAuthority: string;
};

export type AddDriverDocType = {
  id: string;
  docType: string;
  downloadUrl: string;
};

export type AddDVLACodeType = {
  id: string;
  checkCode: string;
};

export type ApproveDriverDocType = {
  userId: string;
  docType: string;
  expiryDate: number;
};

export type RejectDriverDocType = {
  userId: string;
  docType: string;
  reasonOfDecline: string;
};

export type VerifyDvlaCodeType = {
  userId: string;
  isVerified: boolean;
};

export type SetLicenceNumbersType = {
  userId: string;
  dvlaLicenceNumber: string;
  badgeNumber: string;
};

export type ChangeDriverStatusType = {
  userId: string;
  approvedDriver: boolean;
};

export type GetPriceQuoteType = {
  date: string;
  time: string;
  pickup: string;
  destinations: string[];
  vehicleType: string;
  hasPet: boolean;
  meetAndGreet: boolean;
};

export type ChangeDriverPreferencesType = {
  userId: string;
  selectedVehicleTypes: VehicleType[];
  petFriendly: boolean;
};

export type PauseOrResumeJobsType = {
  userId: string;
  pausedRequests: boolean;
};
