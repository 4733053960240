import { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { IoMdAlert } from "react-icons/io";

import {
  LogoContainer,
  NavigationLink,
  NavContainer,
  NavLinks,
  InnerNavContainer,
  MobileNavContainer,
  BurgerMenuButton,
  MobileNavgationLink,
  BurgerMenuButtonContainer,
} from "./header.styles";
import LogoImage from "../../../assets/images/Color logo - no background.svg";
import { auth } from "../../../firebase/config";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { colors } from "../../../style-variables";
import { SpacerVertical } from "../../UI/spacers/spacers.component";

const Header = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const [driverAlert, setDriverAlert] = useState(false);
  const [adminAlert, setAdminAlert] = useState(false);

  const [user] = useAuthState(auth);
  const { currentUser } = useSelector((state: RootState) => state.user);
  const { approvedDriver } = useSelector(
    (state: RootState) => state.approvedDriver
  );
  //personal requests
  const { jobOffers } = useSelector((state: RootState) => state.jobOffers);
  //public requests, currently set limit to 1 in App.tsx onSnapShot, just to indicate that there is at least one public job
  const { jobs } = useSelector((state: RootState) => state.jobs);
  //list of users waiting for approval
  const { userList } = useSelector((state: RootState) => state.userList);

  useEffect(() => {
    if (
      (approvedDriver && approvedDriver.pausedRequests) ||
      (jobOffers && jobOffers.length > 0) ||
      (jobs && jobs.length > 0)
    ) {
      setDriverAlert(true);
    } else if (
      approvedDriver &&
      !approvedDriver.pausedRequests &&
      jobOffers &&
      jobOffers.length === 0
    ) {
      setDriverAlert(false);
    }
  }, [approvedDriver, jobOffers, jobs]);

  useEffect(() => {
    if (userList && userList.length > 0) {
      setAdminAlert(true);
    } else {
      setAdminAlert(false);
    }
  }, [userList]);

  // Using $ in front of Styeld Component prop name to keep React DOM happy
  return (
    <NavContainer $isMobileNavOpen={isMobileNavOpen}>
      <InnerNavContainer>
        <NavLinks>
          <NavigationLink to="/">Home</NavigationLink>
          {user && <NavigationLink to="/profile">My Profile</NavigationLink>}
          {currentUser &&
          currentUser.driversData &&
          currentUser.driversData.licensingAuthority ? (
            <NavigationLink to="/drivers/driver-panel">
              Driver{" "}
              {driverAlert && <IoMdAlert size={24} color={colors.primary} />}
            </NavigationLink>
          ) : (
            <NavigationLink to="/drivers">Drivers</NavigationLink>
          )}
          {currentUser && currentUser.hasAccount ? (
            <NavigationLink to="/accounts">Account</NavigationLink>
          ) : (
            <NavigationLink to="/accounts">Accounts</NavigationLink>
          )}
          {currentUser && currentUser.isBoss && (
            <NavigationLink to="/boss">
              Admin{" "}
              {adminAlert && <IoMdAlert size={24} color={colors.primary} />}
            </NavigationLink>
          )}
          <BurgerMenuButtonContainer>
            <BurgerMenuButton
              onClick={() => {
                setIsMobileNavOpen((currentValue) => !currentValue);
              }}
            >
              {isMobileNavOpen ? <>&#10005;</> : <>&#8801;</>}
            </BurgerMenuButton>
            {(driverAlert || adminAlert) && !isMobileNavOpen && (
              <SpacerVertical $paddingRem={0.7}>
                <IoMdAlert size={24} color={colors.primary} />
              </SpacerVertical>
            )}
          </BurgerMenuButtonContainer>
        </NavLinks>
        <LogoContainer to="/">
          <img src={LogoImage} alt="Rufus" />
        </LogoContainer>
      </InnerNavContainer>
      {isMobileNavOpen && (
        <MobileNavContainer>
          <MobileNavgationLink onClick={() => setIsMobileNavOpen(false)} to="/">
            Home
          </MobileNavgationLink>
          {user && (
            <MobileNavgationLink
              onClick={() => setIsMobileNavOpen(false)}
              to="/profile"
            >
              My Profile
            </MobileNavgationLink>
          )}
          {currentUser &&
          currentUser.driversData &&
          currentUser.driversData.licensingAuthority ? (
            <MobileNavgationLink
              onClick={() => setIsMobileNavOpen(false)}
              to="/drivers/driver-panel"
            >
              Driver{" "}
              {driverAlert && <IoMdAlert size={24} color={colors.primary} />}
            </MobileNavgationLink>
          ) : (
            <MobileNavgationLink
              onClick={() => setIsMobileNavOpen(false)}
              to="/drivers"
            >
              Drivers
            </MobileNavgationLink>
          )}
          {currentUser && currentUser.hasAccount ? (
            <MobileNavgationLink
              onClick={() => setIsMobileNavOpen(false)}
              to="/accounts"
            >
              Account
            </MobileNavgationLink>
          ) : (
            <MobileNavgationLink
              onClick={() => setIsMobileNavOpen(false)}
              to="/accounts"
            >
              Accounts
            </MobileNavgationLink>
          )}
          {currentUser && currentUser.isBoss && (
            <MobileNavgationLink
              onClick={() => setIsMobileNavOpen(false)}
              to="/boss"
            >
              Admin{" "}
              {adminAlert && <IoMdAlert size={24} color={colors.primary} />}
            </MobileNavgationLink>
          )}
        </MobileNavContainer>
      )}
    </NavContainer>
  );
};

export default Header;
